
document.addEventListener("DOMContentLoaded", function(){

    let termsLink = document.getElementById("termsLink"),
        termsModal = document.getElementById('modal-terms'),
        html = document.querySelector('html');

    if(termsLink) {
        termsLink.addEventListener('click', function () {

            if(termsModal) {

                termsModal.classList.toggle('is-active');
                html.classList.add('is-clipped');

                let closers = termsModal.querySelectorAll('.close-modal,.modal-background');

                for (var closer of closers) {
                    closer.addEventListener('click', function(e){
                        e.preventDefault();
                        termsModal.classList.remove('is-active');
                        html.classList.remove('is-clipped');
                    });
                }

                resetMessages(this);
            }
        });
    }

    let togglers = document.querySelectorAll('.toggler');
    for (var toggler of togglers) {
        toggler.addEventListener('click', function(e){

            e.preventDefault();

            var spans = this.getElementsByTagName('span');

            if(this.classList.contains('expand')) {

                for (var span of spans){
                    var itags = span.getElementsByTagName('i');
                    for (var itag of itags){
                        itag.classList.remove('fa-angle-up');
                        itag.classList.add('fa-angle-down');
                    }
                }

            } else {

                for (var span of spans){
                    var itags = span.getElementsByTagName('i');
                    for (var itag of itags){
                        itag.classList.remove('fa-angle-down');
                        itag.classList.add('fa-angle-up');
                    }
                }
            }

            document.getElementById(this.dataset.target).classList.toggle('is-hidden');

            this.classList.toggle('expand');
            this.classList.toggle('collapse');
        });
    }


    function resetMessages(){

        let messages = document.getElementById('accordion_terms')
                               .querySelectorAll('.message-body,.is-collapsible');

        console.log("message count: ",messages.length);

        for (var message of messages){


            if(message.classList.contains('is-hidden')){ continue; }
            console.log("Id: ",message.id);

            message.classList.add('is-hidden');
        }
    }
});